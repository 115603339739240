import Header from "./blog/Header";
import BlogList from "./blog/BlogList";
import { useEffect } from "react";
import { BackToTop } from "components/BackToTop";
export default function Blog(){
  useEffect(() => {
      BackToTop();
  }, [])
    return(
        <>
            <Header />
            <BlogList />
        </>
    )
}
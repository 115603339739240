import {
    NavDropdown,
} from 'react-bootstrap'
import { Link } from "react-router-dom"

export default function ServiceDrpdwn(){

    function unHideIt(id) {
        document.getElementById(id).classList.toggle('d-none');
        document.getElementById(id).classList.toggle('d-md-block');
    }

    return(
        <NavDropdown className="align-text-top lit-drpdwn" title="Service &nbsp;" id="basic-nav-dropdown">
            <div className="lit-themeBg rounded">
                <div className="row">
                    <div className="col-md-6">
                    
                        <p className="mt-3 font-weight-bolder" onClick={e=>{unHideIt('dropServ1')}}>► Designing </p>

                        <ul className="d-none d-md-block" id="dropServ1">
                            <li><Link to="/service/ui-ux"> UI/UX Designing </Link></li>
                            <li><Link to="/service/web-design"> Web Design </Link></li>
                            <li><Link to="/service/react"> React App Design </Link></li>
                            <li><Link to="/service/angular"> Angular App Design </Link></li>
                            <li><Link to="/service/graphic-design"> Graphic Designing </Link></li>
                        </ul>

                        <p className="mt-3 font-weight-bolder" onClick={e=>{unHideIt('dropServ2')}}>► Development </p>
                        <ul className="d-none d-md-block" id="dropServ2">
                            <li><Link to="/service/php"> PHP Web Development </Link></li>
                            <li><Link to="/service/laravel"> Laravel Development </Link></li>
                            <li><Link to="/service/codeigniter"> Codeigniter Development </Link></li>
                            <li><Link to="/service/node"> Node Development </Link></li>
                            <li><Link to="/service/django"> Django Web Development </Link></li>
                        </ul>

                    </div>


                    <div className="col-md-6">
                    
                        <p className="mt-3 font-weight-bolder" onClick={e=>{unHideIt('dropServ3')}}>► Digital Marketing </p>
                        <ul className="d-none d-md-block" id="dropServ3">
                            <li><Link to="/service/seo"> Search Engine Optimization </Link></li>
                            <li><Link to="/service/smm"> Social Media Marketing </Link></li>
                            <li><Link to="/service/smo"> Social Media Optimization </Link></li>
                        </ul>
                        
                        <p className="mt-3 font-weight-bolder" onClick={e=>{unHideIt('dropServ4')}}>► Hosting Services </p>
                        <ul className="d-none d-md-block" id="dropServ4">
                            <li><Link to="/service/aws"> Amazon Web Service </Link></li>
                            <li><Link to="/service/vps"> Virtual Private Server </Link></li>
                        </ul>

                        <p className="mt-3 font-weight-bolder" onClick={e=>{unHideIt('dropServ5')}}>► Mobile App Development </p>
                        <ul className="d-none d-md-block" id="dropServ5">
                            <li><Link to="/service/react-native"> React Native </Link></li>
                            <li><Link to="/service/android"> Android Development </Link></li>
                            <li><Link to="/service/ios"> IOS Development </Link></li>
                        </ul>

                    </div>


                </div>
            </div>
        </NavDropdown>
    )
}
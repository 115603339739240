import Contact from './contact/Contact'
import { useEffect } from "react";
import { BackToTop } from 'components/BackToTop';

export default function Error404(){
    useEffect(() => {
        BackToTop();
    }, [])

    return(
        <>
            <>
                <div
                    className="page-header section-dark lit-404BG lit-small"
                    style={{
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: 'purple',
                    }}
                >
                    {/* <div className="filter" /> */}
                    {/* <div className="content-center">
                        <h1>404</h1>
                    </div> */}
                </div><br /><br />
            </>
            <Contact />
        </>
    )
}
import React from "react";

// reactstrap components
import {
    Card,
    // Container,
    Row,
    Col,
    Carousel,
    CarouselItem,
    // CarouselIndicators,
    // CarouselCaption,
} from "reactstrap";

// core components

const items = [
    {
        title: "Web Development",
        disc: "INCREASE COLLABORATION AND CREATE OPPORTUNITIES"
    },
    {
        title: "Mobile App Development",
        disc: "FOR STARTUPS, SMBS AND ENTERPRISES"
    },
    {
        title: "IOT",
        disc: "PERSONALIZED RETAIL MARKETING AND CONTENT DELIVERY"
    },
    {
        title: "SEO Digital marketing",
        disc: "DO LESS, REACH MORE"
    },
    {
        title: "Graphic Design",
        disc: "OUR LOVE CAN CROSS THE UNIVERSE"
    },
    {
        title: "Training",
        disc: "FOR STUDENTS TO FRESHERS"
    },
];


export default function Slider()  {
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [animating, setAnimating] = React.useState(false);
    const onExiting = () => {
        setAnimating(true);
    };
    const onExited = () => {
        setAnimating(false);
    };
    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };
    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };
    // const goToIndex = (newIndex) => {
    //     if (animating) return;
    //     setActiveIndex(newIndex);
    // };
    return (
        <>
            <div className="section pt-o" id="carousel">
                    <Row>
                        <Col className="ml-auto mr-auto" md="12">
                            <Card className="page-carousel">
                                <Carousel
                                    activeIndex={activeIndex}
                                    next={next}
                                    previous={previous}
                                >
                                    {/* <CarouselIndicators
                                        items={items}
                                        activeIndex={activeIndex}
                                        onClickHandler={goToIndex}
                                    /> */}
                                    {items.map((item, index) => {
                                        return (
                                            <CarouselItem
                                                onExiting={onExiting}
                                                onExited={onExited}
                                                key={index}
                                            >
                                                
                                                <h1 className="lit-presentation-title">{item.title}</h1>
                                                <h4>{item.disc}</h4>
                                            </CarouselItem> 
                                        );
                                    })}
                                    <a
                                        className="left carousel-control carousel-control-prev"
                                        data-slide="prev"
                                        href="#pablo"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            previous();
                                        }}
                                        role="button"
                                    >
                                        <span className="fa fa-angle-left" />
                                        <span className="sr-only">Previous</span>
                                    </a>
                                    <a
                                        className="right carousel-control carousel-control-next"
                                        data-slide="next"
                                        href="#pablo"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            next();
                                        }}
                                        role="button"
                                    >
                                        <span className="fa fa-angle-right" />
                                        <span className="sr-only">Next</span>
                                    </a>
                                </Carousel>
                            </Card>
                        </Col>
                    </Row>
            </div>{" "}
        </>
    );
}



import React from "react";
import {
  Card,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
  // CarouselCaption,
} from "reactstrap";

// core components

const items = [
  {
    name: 'Darshan Kansara',
    comment: 'It takes a lot of people to coordinate a massive plant for all the steps necessary for shipment. Thanks to LIOM IT Hub team who replaced our paper-based scheduling process into a most effective mobility solution where people can communicate with colleagues & supervisors, and handle unplanned scheduling needs.',
    rating: 5,
    dateTime: '2020-6-2 13:13:13'
  },
  {
    name: 'Hitarth Patel',
    comment: 'We are one of the oldest, fast and safe Crypto exchange service in Asia. LIOM IT Hub helped us provide quality services to our customers and are constantly working on making our Crypto wallet products better.',
    rating: 4,
    dateTime: '2021-4-7 20:00:00'
  }
];


export default function Testimonials() {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  return (
    <div className="lit-testimonials d-none d-md-block">

      <div className="container  lit-container lit-containerNoMargin">
        <div className="section pt-o" id="carousel">
          <Container>
            <Row>
              <h4 className="col-12 text-center">Client's <i className="fa fa-comments"></i></h4>
              <Col className="ml-auto mr-auto" md="8">
                <Card className="page-carousel">
                  <Carousel
                    activeIndex={activeIndex}
                    next={next}
                    previous={previous}
                  >
                    <CarouselIndicators
                      items={items}
                      activeIndex={activeIndex}
                      onClickHandler={goToIndex}
                    />
                    {items.map((item, index) => {
                      return (
                        <CarouselItem
                          className="lit-testItem"
                          onExiting={onExiting}
                          onExited={onExited}
                          key={index}
                        >
                          <div className="row py-4 px-5 lit-testItemUpper">
                            <span className="text-justify px-5 lit-testComment">{item.comment}</span>
                            <p className="text-right px-5 lit-testName">- {item.name} | {item.dateTime}</p>
                          </div>
                          <div className="row py-4 lit-testItemBottom">
                            <p className="text-center">
                              <i className={(item.rating >= 1) ? 'fa fa-star' : 'fa fa-star-o '}></i>
                              <i className={(item.rating >= 2) ? 'fa fa-star' : 'fa fa-star-o '}></i>
                              <i className={(item.rating >= 3) ? 'fa fa-star' : 'fa fa-star-o '}></i>
                              <i className={(item.rating >= 4) ? 'fa fa-star' : 'fa fa-star-o '}></i>
                              <i className={(item.rating >= 5) ? 'fa fa-star' : 'fa fa-star-o '}></i>
                            </p>
                          </div>
                        </CarouselItem>
                      );
                    })}
                    <a
                      className="left carousel-control carousel-control-prev"
                      data-slide="prev"
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        previous();
                      }}
                      role="button"
                    >
                      <span className="fa fa-angle-left" />
                      <span className="sr-only">Previous</span>
                    </a>
                    <a
                      className="right carousel-control carousel-control-next"
                      data-slide="next"
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        next();
                      }}
                      role="button"
                    >
                      <span className="fa fa-angle-right" />
                      <span className="sr-only">Next</span>
                    </a>
                  </Carousel>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}
import React from 'react'
import Contact from './contact/Contact'
import Header from './contact/Header'
import { useEffect } from "react";
import { BackToTop } from 'components/BackToTop';

export default function ContactPage() { 
  useEffect(() => {
      BackToTop();
  }, [])
    return (
        <>
            <Header />
            <Contact />
        </>
    )
}
import {React, useEffect} from 'react';
import { useParams, Redirect } from 'react-router-dom';
import {services} from "./mainData";
import Process from "../home/Process";
import Keyach from "../home/Keyach";
import Contact from "../contact/Contact";
import {Link} from "react-router-dom"
import { BackToTop } from 'components/BackToTop';

export default function ServicesDemo(){
    
    const {id} = useParams()
    const techURL = 'technology/'
    const serviceURL = 'service/'

    useEffect(() => {
        BackToTop();
    }, [id])


    if(services.hasOwnProperty(id)) var pageData = services[id];
    else return <Redirect to='/404' />


    return(
        <>
            <div
                className="page-header section-dark lit-small "
                style={{
                    backgroundColor: 'var(--darkBg)',
                    "backgroundImage": "url('" + require("../../assets/img/litImages/" + serviceURL + pageData.headerSrc).default + "')",
                    "backgroundRepeat": "no-repeat",
                    "backgroundPosition": "center",
                    "backgroundSize": "cover",
                }}
            >
            <div className="filter" />
                <div className="content-center ">
                    <div className="container">

                        <div className="row">

                            <div className="col-12 text-center px-5 my-auto lit-textTheme">
                                <h2><b>{pageData.title}</b></h2><br />
                                <h5><b>{pageData.tagLine}</b></h5>
                            </div>

                        </div>

                    </div>
                </div>
            </div>


            <div className="container text-center mb-5">
                <h2 className="lit-servicesTitle">{pageData.title}</h2>
                <hr className="w-50 mx-auto" />
                <span className="lit-servicesContent">{pageData.mainContent}</span>
                <div className="mt-5 row">
                    <div className="col-md-7 d-none d-md-block px-5 my-auto">
                        <img src={require("../../assets/img/litImages/" + serviceURL + pageData.contSrc).default} alt={pageData.title} width="100%"/>
                    </div>
                    <div className="col-md-5 my-auto text-left">
                        
                        {
                            pageData.contList.map((data,index)=>{
                                return(
                                    <div key = {index}>
                                        <h5 className="lit-servicesHead pl-2 pt-4">{data.contPointsTitle}</h5>
                                        {
                                            data.contPoints.map((data2,index2)=>
                                                {
                                                    return(
                                                        <div className="row" key = {index2}>
                                                            <div className="col-2 text-right">
                                                                <i className="fa fa-check my-2 text-primary"></i>
                                                            </div>
                                                            <div className="col-10 lit-servicesList">
                                                                {data2} 
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            )
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

            {
                (pageData.hasOwnProperty('technologies'))?
                <div className="container lit-container mb-5">
                    <div className="row">
                        <h4 className="col-12 mt-3">Technologies</h4>
                        {
                            pageData.technologies.map((data,index)=>{
                                let currImg = techURL + data;
                                return(
                                    <div className="col-md-2 col-sm-4 col-6 p-1 p-sm-2 p-md-3 text-center" key={index}>
                                        <img src={require("../../assets/img/litImages/" + currImg).default} alt="Services in LIOM IT HUB" width="150" />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                :""
            }



            <div className="container lit-container mb-5">
                <div className="row">
                    <h4 className="col-12 mt-3">Related Services</h4>
                    {
                        pageData.related.map((data,index)=>{
                            let currImg = serviceURL + services[data].headerSrc;
                            return(
                                <div className="col-md-3 col-sm-6 p-1 p-sm-2 p-md-3 text-center" key={index}>
                                    <Link to={"./"+data} className="rounded shadow-lg">
                                        <div className="rounded shadow-lg"
                                            style={{
                                                "backgroundImage": "url('"+ require("../../assets/img/litImages/" + currImg).default +"')",
                                                "backgroundRepeat": "no-repeat",
                                                "backgroundPosition": "center",
                                                "backgroundSize": "cover",
                                                "height": "150px",
                                                "overflow": "hidden"
                                            }}
                                        >
                                            <p className="lit-textTheme"
                                                style={{
                                                    "height": "100%",
                                                    "width": "100%",
                                                    "backdropFilter": "blur(0px)",
                                                    "background": "rgba(0,0,0,0.5)"
                                                }}
                                            ><b style={{
                                                "position": "relative",
                                                "top": "calc(50% - 10px)"
                                            }}>{services[data].title}</b></p>
                                        </div>
                                    </Link>
                                    
                                    {/* <img src={require("../../assets/img/litImages/" + currImg).default} alt="Services in LIOM IT HUB" width="150" /> */}
                                </div>
                            )
                        })
                    }
                </div>
            </div>

        
            <Process />
            <Keyach />
            <Contact />
        </>
    )
}
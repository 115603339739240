import Header from "./about/Header";
import Www from "./about/Www";
import Served from "./about/Served";
import Contact from "./contact/Contact"
import WhatWeDo from "./home/WhatWeDo";
import { useEffect } from "react";
import { BackToTop } from "components/BackToTop";

export default function About(){

  useEffect(() => {
      BackToTop();
  }, [])

    return(
        <>
            <Header />
            <Www />
            <div
              style={{
                "backgroundImage": "url('" + require('assets/img/litImages/aboutImg.png').default + "')",
                "backgroundSize": "cover",
                "backgroundRepeat": "no-repeat",
                "width": "100%",
                "backgroundPosition": "center",
                "height": "400px",
                "backgroundAttachment": "fixed",
                "boxShadow": "0px 0px 5px 5px #fff inset"
              }}
            ></div>
            <WhatWeDo />
            <Served />
            {/* 
                Team
                Working with clients in {Country}

                https://static-cse.canva.com/blob/141406/07_CleanGraphics.c13c4aa5.jpg
                https://static-cse.canva.com/blob/141433/33.SimpleVisuals.1441432b.jpg
             */}
             <Contact />
        </>
    )
}
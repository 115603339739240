export default function Www(){
    return(
        <div className="container lit-container">
            <div className="row lit-wwd">
                <div  className="text-center pb-4">
                    <h3 className="lit-textThemeAlt">
                        "We are a close family of talented and driven people that strive to deliver quality and honesty."
                    </h3>
                    {/* <hr className="mx-auto w-50" /> */}
                </div>
                {/* <h4>What We Do</h4> */}
                <div className="col-md-4 col-sm-6 px-3 py-4 border-top mb-2">
                    <h5>Who we are?</h5>
                    <hr className="w-50" />
                    <p>Liom IT Hub is a close team of creative professionals based out of Ahmedabad, IN. We are Musicians, Gamers, Jocksters, Hackers, Coders and More... We are passionate about paterning with good people and organizations to produce exceptional work that betters the world around.</p>
                </div>
                <div className="col-md-4 col-sm-6 px-3 py-4 border-top mb-2">
                    <h5>What we do?</h5>
                    <hr className="w-50" />
                    <p>Our skills are specific and fined-tuned. We are specialists, not generalists. We create visual branding systems that enable organizations to put their best face forward to their audience. This includes everything from the logo, typography, and iconography to website design, app design, and website development.</p>
                </div>
                <div className="col-md-4 col-sm-6 px-3 py-4 border-top mb-2">
                    <h5>Why we do it?</h5>
                    <hr className="w-50" />
                    <p>That's simple. We exist to create things that promote growth and enrich lives in organizations and communications. It isn't enough to make somethinf that looks good. We endeavor toward work that drives action, generates results, and makes for change.</p>
                </div>
            </div>
        </div>
    )
}
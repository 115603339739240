import React from 'react'
// import {Link} from 'react-router-dom'
// import india from '../../assets/img/litImages/india.svg'
// import callIconGiffy from '../../assets/img/litImages/callIconGiffy.gif'

export default function Topbar(){
    return(
        <div className="lit-topbar text-dark">
            <span id="contacts" className="d-none">

                <a href="tel:+91 8866105030"><i className="fa fa-phone-alt"></i></a>
                <a href="mailto:info@liomithub.com"><i className="fa fa-envelope"></i></a>
                <a href="https://wa.me/+918866105030?text=Hello..."><i className="fa fa-whatsapp"></i></a>
            </span>
            <i className="fa fa-address-book" style={{cursor: 'pointer'}} onClick={()=>{document.getElementById('contacts').classList.toggle('d-none')}}></i>
        </div>

    )
}
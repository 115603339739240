
export default function Looking(){
    return(
        <div className="container  lit-container">

            <div className="row">
                <div className="col-md-12 text-center">
                <h4 className="p-0 m-0">Looking for a next career break? </h4>  
                <hr className="w-50 mx-auto" />
                
                </div>  
                <div className="col-md-10 text-center mx-auto">
                    <span>Working at liom is to indulge in a perfect work-life balance. We recognize the right talent and put them in the right place while respecting individual priorities and interest areas. We always believe in creating a team of connected & 
                        motivated people driven by our mentoring promises to help you grow and excel in your professional career journey.
                        We are hiring fast-thinking, innovative, passionate team players who want to achieve professional growth with us. Check out our current openings and share your resume on liomithub@gmail.com to be a part of our growing team.
                    </span>
                </div>
            </div>
        </div>
    )
}
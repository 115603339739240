import React from "react";
import india from '../../assets/img/litImages/india.svg'

export default function GloOffice() {
    return (
        <div className="lit-bg-ws">
        <div className="container  lit-container lit-containerNoMargin">
        <div className="row py-5 lit-gloOff">
           <h4 className="col-12 my-3">Global Office</h4>
            <div className="col-md-4 my-2">
                <div className="shadow p-3 lit-gloOffCard" id="lit-offInd"> 
                    <img src={india} width="15" alt="Indian Flag" /> <span className="px-2">LIOM IT HUB PVT. LTD.</span>
                    <br/>
                    <p className="px-4 mx-1">
                        Third Floor, Rameshwar Aprt,<br />
                        Vastrapur Rly Stat Road,<br />
                        Ahmedabad : 380016.<br />
                    </p>
                    <p><i className="fa fa-phone" style={{ transform: 'rotateY(180deg)' }} aria-hidden="true"></i> &nbsp; <a href="tel:+91 8866105030">+91 8866105030</a></p>
                    <p><i className="fa fa-envelope" aria-hidden="true"></i> &nbsp; <a href="mailto:info@liomithub.com">info@liomithub.com</a></p>
                </div>
            </div>
            {/* <div className="col-md-4 my-2">
                <div className="shadow p-3 lit-gloOffCard" id="lit-offCan">   
                    <img src={india} width="15" alt="Indian Flag" /> <span className="px-2">LIOM IT HUB PVT. LTD.</span>
                    <br/>
                    <p className="px-4 mx-1">
                        Third Floor, Rameshwar Aprt,<br />
                        Vastrapur Rly Stat Road,<br />
                        Ahmedabad : 380016.<br />
                    </p>
                    <p><i className="fa fa-phone" style={{ transform: 'rotateY(180deg)' }} aria-hidden="true"></i> &nbsp; <a href="tel:+91 8866105040">+91 8866105040</a></p>
                    <p><i className="fa fa-envelope" aria-hidden="true"></i> &nbsp; <a href="mailto:info@liomithub.com">info@liomithub.com</a></p>
                
                </div>
            </div> */}
        </div>   
        </div>   
        </div>
    )
}
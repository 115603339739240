import {React, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import Process from "./home/Process";
import Keyach from "./home/Keyach";
import Contact from "./contact/Contact";
import { BackToTop } from 'components/BackToTop';

export default function Technologies(){
    
    const {id} = useParams()

    useEffect(() => {
        BackToTop();
    }, [id])


    return(
        <>
            <div
                className="page-header section-dark lit-small "
                style={{
                    backgroundColor: 'var(--darkBg)',
                    "backgroundImage": "url('" + require("assets/img/litImages/techBG.jpg").default + "')",
                    "backgroundRepeat": "no-repeat",
                    "backgroundPosition": "center",
                    "backgroundSize": "cover",
                }}
            >
                <div className="filter" />
                <div className="content-center ">
                    <div className="container">

                        <div className="row">

                            <div className="col-12 text-center px-5 my-auto lit-textTheme">
                                <h2><b>Technologies</b></h2><br />
                                <h5><b>Our Speciality</b></h5>
                            </div>

                        </div>

                    </div>
                </div>
            </div>


           
                <div className="container lit-container mb-5">
                    <div className="row">
                        <h4 className="col-12 mt-3">Technologies</h4>
                        <div className="col-12 mt-1 text-center">

                            <img src={require("../assets/img/litImages/technology/php.png").default} alt="PHP Technology in LIOM IT HUB" className="lit-techImg mx-3 my-3" />
                            <img src={require("../assets/img/litImages/technology/node.png").default} alt="Node Technology in LIOM IT HUB" className="lit-techImg mx-3 my-3" />
                            <img src={require("../assets/img/litImages/technology/js.png").default} alt="JS Technology in LIOM IT HUB" className="lit-techImg mx-3 my-3" />
                            <img src={require("../assets/img/litImages/technology/react.png").default} alt="React Technology in LIOM IT HUB" className="lit-techImg mx-3 my-3" />
                            <img src={require("../assets/img/litImages/technology/angular.png").default} alt="angular Technology in LIOM IT HUB" className="lit-techImg mx-3 my-3" />
                            <img src={require("../assets/img/litImages/technology/ae.png").default} alt="ae Technology in LIOM IT HUB" className="lit-techImg mx-3 my-3" />
                            <img src={require("../assets/img/litImages/technology/canva.png").default} alt="canva Technology in LIOM IT HUB" className="lit-techImg mx-3 my-3" />
                            <img src={require("../assets/img/litImages/technology/coral.png").default} alt="coral Technology in LIOM IT HUB" className="lit-techImg mx-3 my-3" />
                            <img src={require("../assets/img/litImages/technology/css.png").default} alt="css Technology in LIOM IT HUB" className="lit-techImg mx-3 my-3" />
                            <img src={require("../assets/img/litImages/technology/figma.png").default} alt="figma Technology in LIOM IT HUB" className="lit-techImg mx-3 my-3" />
                            <img src={require("../assets/img/litImages/technology/html.png").default} alt="html Technology in LIOM IT HUB" className="lit-techImg mx-3 my-3" />
                            <img src={require("../assets/img/litImages/technology/jquery.png").default} alt="jquery Technology in LIOM IT HUB" className="lit-techImg mx-3 my-3" />
                            <img src={require("../assets/img/litImages/technology/mern.png").default} alt="mern Technology in LIOM IT HUB" className="lit-techImg mx-3 my-3" />
                            <img src={require("../assets/img/litImages/technology/mongo.png").default} alt="mongo Technology in LIOM IT HUB" className="lit-techImg mx-3 my-3" />
                            <img src={require("../assets/img/litImages/technology/mysql.png").default} alt="mysql Technology in LIOM IT HUB" className="lit-techImg mx-3 my-3" />
                            <img src={require("../assets/img/litImages/technology/ps.png").default} alt="ps Technology in LIOM IT HUB" className="lit-techImg mx-3 my-3" />
                            <img src={require("../assets/img/litImages/technology/py.png").default} alt="py Technology in LIOM IT HUB" className="lit-techImg mx-3 my-3" />
                            <img src={require("../assets/img/litImages/technology/wordpress.png").default} alt="wordpress Technology in LIOM IT HUB" className="lit-techImg mx-3 my-3" />
                            <img src={require("../assets/img/litImages/technology/xd.png").default} alt="XD Technology in LIOM IT HUB" className="lit-techImg mx-3 my-3" />
                            <img src={require("../assets/img/litImages/technology/laravel.png").default} alt="laravel Technology in LIOM IT HUB" className="lit-techImg mx-3 my-3" />
                            <img src={require("../assets/img/litImages/technology/codeigniter.png").default} alt="codeigniter Technology in LIOM IT HUB" className="lit-techImg mx-3 my-3" />
                            <img src={require("../assets/img/litImages/technology/bootstrap.png").default} alt="bootstrap Technology in LIOM IT HUB" className="lit-techImg mx-3 my-3" />
                            <img src={require("../assets/img/litImages/technology/aws.png").default} alt="aws Technology in LIOM IT HUB" className="lit-techImg mx-3 my-3" />
                            <img src={require("../assets/img/litImages/technology/react.png").default} alt="react native Technology in LIOM IT HUB" className="lit-techImg mx-3 my-3" />

                        </div>
                    </div>
                </div>
              
        
            <Process />
            <Keyach />
            <Contact />
        </>
    )
}
import React from "react";
// import Slider from "./Slider";

export default function Header() {
  return (
    <>
      <div className="page-header section-dark lit-small lit-contactBG">
        <div className="filter" />
        <div className="content-center">
            {/* <Slider /> */}
            <h1>Contact Us</h1>
        </div>
        {/* <div
          className="moving-clouds"
          style={{
            backgroundImage:
              "url(" + require("assets/img/clouds.png").default + ")",
          }}
        /> */}
      </div><br /><br />
    </>
  );
}


import React from "react";
// nodejs library that concatenates strings
import classnames from "classnames";
import { Link } from "react-router-dom"
import logoWhite from 'assets/img/litImages/logoWhite.png'
import logoNormal from 'assets/img/litImages/logoNormal.png'
// reactstrap components
import {
  Button,
  Collapse,
  // NavbarBrand,
  Navbar,
  NavItem,
  // NavLink,
  Nav,
  Container,
} from "reactstrap";
import ServiceDrpdwn from "./ServiceDprdwn";

export default function CommonNavbar() {

  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299
      ) {
        setNavbarColor("");
        document.getElementById('lit-logo').src = logoNormal
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
        ) {
          setNavbarColor("navbar-transparent");
          document.getElementById('lit-logo').src = logoWhite
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <Navbar className={classnames("fixed-top", navbarColor)} expand="lg">
      <Container>
        <div className="navbar-translate">
          <Link to="/" className="navbar-brand">
            {/* <div className="lit-logo"></div> */}
            <img src={logoWhite} alt="LIOM IT HUB" width="100" id="lit-logo" />
          </Link>
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse,
            })}
            onClick={toggleNavbarCollapse}
            id="mainNavBtn"
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>


            <Link to="/about" className="my-auto mx-md-1">
              <NavItem className="lit-drpdwn"> About </NavItem>
            </Link>

            <ServiceDrpdwn />
            
            <Link to="/career" className="my-auto mx-md-1">
              <NavItem className="lit-drpdwn"> Career </NavItem>
            </Link>

            <Link to="/technologies" className="my-auto mx-md-1">
              <NavItem className="lit-drpdwn"> Technologies </NavItem>
            </Link>


            <Link to="/training" className="my-auto mx-md-1">
              <NavItem className="lit-drpdwn"> Training </NavItem>
            </Link>

            <NavItem>
              <Link to="/portfolio">
                <Button
                  className="btn-round"
                  color="danger"
                >
                  <i className="fa fa-sellsy"></i> Portfolio
                </Button>
              </Link>
            </NavItem>

          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

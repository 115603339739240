import React from 'react';
import Header from './home/Header';
import WhatWeDo from './home/WhatWeDo';
import Process from './home/Process';
import Keyach from './home/Keyach';
import GloOffice from './home/GloOffice';
import Contact from './contact/Contact';
import Testimonials from './home/Testimonials';
// import FeaturedBlog from './home/FeaturedBlog';
import { useEffect } from "react";
import { BackToTop } from 'components/BackToTop';
export default function Home() {
  useEffect(() => {
      BackToTop();
  }, [])
    return (
        <>
            <Header />
            <WhatWeDo />
            <Process />
            <Keyach />
            <GloOffice />
            <Testimonials />
            {/* <FeaturedBlog /> */}
            <Contact />
        </>
    )
}
import Header from "./portfolio/Header";
import PortDes from "./portfolio/PortDes";
import { useEffect } from "react";
import { BackToTop } from "components/BackToTop";
export default function Portfolio(){
  useEffect(() => {
      BackToTop();
  }, [])
    return(
        <>
            <Header />
            <PortDes />
        </>
    )
}
import React from "react";
import keyach1 from "../../assets/img/litImages/keyach1.png"
import keyach2 from "../../assets/img/litImages/keyach2.png"
import keyach3 from "../../assets/img/litImages/keyach3.png"

export default function Keyach() {
    return (
          <div className="container  lit-container ">
        <div className="row py-5 lit-keyAch ">
           <h4 className="col-12 my-3">Key Achievements</h4>
            <div className="col-sm-4 text-center my-2">
                <div className="shadow-sm border p-3 lit-keyAchCard rounded"> 
                    <img src={keyach1} alt="successful project" /> 
                    <h5 className="mt-2">26+</h5>
                    <hr className="w-50 mx-auto" />
                    <h5>Successful Projects</h5>
                </div>
            </div>
            <div className="col-sm-4 text-center my-2">
                <div className="shadow-sm border p-3 lit-keyAchCard rounded">   
                    <img src={keyach2} alt="Countries Served" /> 
                    <h5 className="mt-2">2</h5>
                    <hr className="w-50 mx-auto" />
                    <h5>Countries Served</h5>
                </div>
            </div>
            <div className="col-sm-4 text-center my-2">
                <div className="shadow-sm border p-3 lit-keyAchCard rounded"> 
                     <img src={keyach3} alt="Skill Engineers" /> 
                     <h5 className="mt-2">10+</h5>
                    <hr className="w-50 mx-auto" />
                    <h5>Skill Engineers</h5>
                </div>
            </div>
        </div>   
        </div>   
    )
}
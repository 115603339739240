import React from "react";
import Contactimg from "../../assets/img/litImages/contact.svg"

export default function Contact() {
  return (
    <div className=" lit-container container">
      <div className="row py-4">
        <h4 className="col-12 my-3">Let's Connect</h4>
        <div className="col-md-6 d-none d-md-block px-5 my-auto">
            <img src={Contactimg} alt="contact-img" width="100%" />
        </div>
        <div className="col-md-6 my-auto">
          <form>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Name</label>
              <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Name" />
              <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
            </div>

            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Email address</label>
              <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />
              
            </div>

            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Phone</label>
              <input type="tel" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Phone Number" />
              
            </div>

            <div className="form-group">
              <label className="exampleFormControlTextarea1">Example textarea</label>
              <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
            </div>
            
          
            <button type="submit" className="btn btn-danger pull-right px-5">Submit</button>
          </form>
        </div>
      </div>

    </div>
  );
}


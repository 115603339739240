import React from "react";

import webDev from "../../assets/img/litImages/web-development.svg"
import appDev from "../../assets/img/litImages/app-development.svg"
import iot from "../../assets/img/litImages/iot.svg"
import seo from "../../assets/img/litImages/seo.svg"
import art from "../../assets/img/litImages/art.svg"
// import training from "../../assets/img/litImages/training.svg"

export default function WhatWeDo() {
    return (
        <div className="container lit-container">
            <div className="row lit-wwd">
                <h4>What We Do</h4>
                <div className="col-md-4 col-sm-6 px-3 border-bottom border-right lit-wwdItem text-center">
                    <img src={webDev} alt="Web Development" />
                    <h5>Web Development</h5>
                    <hr className="mx-auto w-50" />
                    <p>We offer secure, scalable and modern web application development services to help you seamlessly connect to your customers and partners.</p>
                </div>
                <div className="col-md-4 col-sm-6 px-3 border-bottom border-right lit-wwdItem text-center">
                    <img src={appDev} alt="App Development" />
                    <h5>App Development</h5>
                    <hr className="mx-auto w-50" />
                    <p>Mobile app development is a process for building mobile applications that run on mobile devices. These applications can either be pre-installed or downloaded and installed by the user later.</p>
                </div>
                <div className="col-md-4 col-sm-6 px-3 border-bottom lit-wwdItem text-center">
                    <img src={iot} alt="Internet of Things" />
                    <h5>Internet Of Things</h5>
                    <hr className="mx-auto w-50" />
                    <p>The Internet of Things, or IoT, refers to the billions of physical devices around the world that are now connected to the internet, all collecting and sharing data.</p>

                </div>
                <div className="col-md-4 col-sm-6 px-3 lit-wwdItem border-right  text-center">
                    <img src={seo} alt="Search Engine Optimization" />
                    <h5>Search Engine Optimization</h5>
                    <hr className="mx-auto w-50" />
                    <p>SEO which is the practice of increasing the quantity and quality of traffic to your website through organic search engine results.</p>

                </div>
                <div className="col-md-4 col-sm-6 px-3 lit-wwdItem border-right  text-center">
                    <img src={art} alt="Graphics Designing" />
                    <h5>Graphics Designing</h5>
                    <hr className="mx-auto w-50" />
                    <p>Graphic Designing may lead Users to a well-designed landing page that reinforces that same imagery.</p>

                </div>
                <div className="col-md-4 col-sm-6 px-3 lit-wwdItem text-center">
                    <img src={webDev} alt="Web Designing" />
                    <h5>Web Designing</h5>
                    <hr className="mx-auto w-50" />
                    <p>Web Designing is a creative field and we are expertise to build attractive and intuitive web designs.</p>
                </div>
            </div>
        </div>
    );
}
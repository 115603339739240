import { BackToTop } from 'components/BackToTop'
import React from 'react'
import { Link } from 'react-router-dom'
import india from '../../assets/img/litImages/india.svg'
export default function Footer() {
    
    
    return (
        <>
            <div className="lit-footerback">
                <div className="container">
                    <div className="row pt-3">
                        <div className="col-md-4">
                            <h3 className="lit-footer-heading">Services</h3>

                            <span><i className="fa fa-angle-double-right" aria-hidden="true"></i> <Link to="/service/web-design">Web Designing </Link></span>
                            <span><i className="fa fa-angle-double-right" aria-hidden="true"></i> <Link to="/service/ui-ux">UI & UX Design</Link></span>
                            <span><i className="fa fa-angle-double-right" aria-hidden="true"></i> <Link to="/service/graphic-design">Graphic Design</Link></span>
                            <span><i className="fa fa-angle-double-right" aria-hidden="true"></i> <Link to="/service/seo">Search Engine Optimization</Link></span>
                            <span><i className="fa fa-angle-double-right" aria-hidden="true"></i> <Link to="/service/smm">Social Media Marketing</Link></span>
                            <span><i className="fa fa-angle-double-right" aria-hidden="true"></i> <Link to="/service/smo">Social Media Optimization</Link></span>
                            <span><i className="fa fa-angle-double-right" aria-hidden="true"></i> <Link to="/service/aws">Amazon Web Services</Link></span>

                        </div>
                        <div className="col-md-4">
                            <h3 className="lit-footer-heading">Technologies</h3>

                            <span><i className="fa fa-angle-double-right" aria-hidden="true"></i> <Link to="/service/php">PHP Web Development </Link></span>
                            <span><i className="fa fa-angle-double-right" aria-hidden="true"></i> <Link to="/service/laravel">Laravel Development</Link></span>
                            <span><i className="fa fa-angle-double-right" aria-hidden="true"></i> <Link to="/service/codeigniter">Codeigniter Development</Link></span>
                            <span><i className="fa fa-angle-double-right" aria-hidden="true"></i> <Link to="/service/react">React Development</Link></span>
                            <span><i className="fa fa-angle-double-right" aria-hidden="true"></i> <Link to="/service/node">Node.JS Development</Link></span>
                            <span><i className="fa fa-angle-double-right" aria-hidden="true"></i> <Link to="/service/ios">iPhone App Development</Link></span>
                            <span><i className="fa fa-angle-double-right" aria-hidden="true"></i> <Link to="/service/react-native">React Native Development</Link></span>


                        </div>
                        <div className="col-md-4">
                            <h3 className="lit-footer-heading">Contact Us</h3>

                            <span>

                                <h6><i className="fa fa-home" aria-hidden="true"></i> Head Office</h6>
                            </span>
                            <p>
                                Third Floor, Rameshwar Aprt,<br />
                                Vastrapur Rly Stat Road,<br />
                                Ahmedabad : 380016.
                            </p>

                            <span>

                                <h6><i className="fa fa-phone" aria-hidden="true" style={{ transform: 'rotateY(180deg)' }}></i> Phone</h6>
                            </span>
                            <p>
                                <img src={india} width="15" alt="india" /> &nbsp; <a href="tel:+91 8866105030">+91 8866105030</a>

                                <span><i className="fa fa-envelope" aria-hidden="true"></i> &nbsp; <a href="mailto:info@liomithub.com">info@liomithub.com</a></span>
                                <span>
                                    <a href="https://www.facebook.com/liomithub/" rel="noreferrer" target="_blank"><i className="fa fa-facebook pr-5"></i></a>
                                    <a href="https://twitter.com/liomithub" rel="noreferrer" target="_blank"><i className="fa fa-twitter pr-5"></i></a>
                                    <a href="https://in.linkedin.com/company/liomithub" rel="noreferrer" target="_blank"><i className="fa fa-linkedin pr-5"></i></a>
                                    <a href="https://www.instagram.com/liomithub/" rel="noreferrer" target="_blank"><i className="fa fa-instagram pr-5"></i></a>
                                </span>
                            </p>


                        </div>
                        <div className="row pt-1">
                            <hr className="w-50 mx-auto" />
                            <div className="pb-2 col-md-12 text-center">
                                <span>&copy; Liom IT Hub. All rights reserved</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="lit-backtotop" onClick={BackToTop} id="backToTopBtn"><i className="fa fa-arrow-up"></i></div>
        </>
    )
}
import Contact from './contact/Contact'
import Header from './training/Header'
import { useEffect } from "react";
import { trainingContent } from './training/trainingContent';
import { BackToTop } from 'components/BackToTop';

export default function Training(){
    useEffect(() => {
        BackToTop();
    }, [])

    return(
        <>
            <Header />

            <div className="container lit-container">
                <div className="row">
                    {
                        trainingContent.map((data,index)=>{
                            return(
                                <div className="col-12 " key={index}>
                                    <div className="row lit-bg-ws rounded shadow-sm pb-2">
                                        <div className={((index+1)%2) ? "col-md-4" : "col-md-4 order-5" }>
                                            <div className="row lit-my-auto">
                                                {
                                                    data.img.map((dataImg, indexImg)=>{
                                                        return(
                                                            <div key={indexImg} className="col text-center p-2 my-auto">
                                                                <img src={require('assets/img/litImages/technology/' + dataImg + '.png').default} alt={data.title + ' in ' + dataImg} width="75" />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div className={((index+1)%2) ? "col-md-8" : "col-md-8 order-1" }>
                                            <h4>{data.title}</h4>
                                            <p className="text-justify">{data.content}</p>
                                        </div>
                                    </div>
                                    { (((index+1) !== trainingContent.length) ? <hr className="w-50 mx-auto" /> : "") }
                                </div>
                            )
                        })
                    }
                </div>
            </div>

            <Contact />
        </>
    )
}
import React from 'react';
import {portfolios} from './Sources'

export default function PortDes(){

    return(
        <>
            <div className="container pb-5">
                    <h2 className="lit-textThemeAlt text-center">Portfolio</h2>
                    <hr className="w-50 mx-auto" />
                <div className="row justify-content-center">
                    {
                        portfolios.map((data, index)=>{
                            let newKey = 'Portfolio Img '+index
                            return(
                                <div className="col-md-4 col-sm-6 lit-portfolioItem mb-5" key={newKey}>
                                    <img src={data.src} alt={data.title} />
                                    <div>
                                        <span className="lit-textTheme"><a href={data.url} target="_blank" rel="noreferrer">{data.title}</a></span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}
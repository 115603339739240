import perfect from '../../assets/img/portfolio/perfect-industries.png'
import dogo from '../../assets/img/portfolio/dogo.png'
// import excell from '../../assets/img/portfolio/excellencia.png'
// import a1Health from '../../assets/img/portfolio/a1-healthcare.png'
import kangaroo from '../../assets/img/portfolio/kangaroo-industries.png'
import siddhimart from '../../assets/img/portfolio/siddhimart.png'
import madhav from '../../assets/img/portfolio/madhav-engineering.png'
import legalAccure from '../../assets/img/portfolio/legalaccure.png'
import mastercoteind from '../../assets/img/portfolio/mastercoteind.png'
import gacmaninagar from '../../assets/img/portfolio/gacmaninagar.png'
import gacc from '../../assets/img/portfolio/gacc.png'
import advanceimagingsolution from '../../assets/img/portfolio/advanceimagingsolution.png'
import tfipl from '../../assets/img/portfolio/tfipl.png'
// import tangle from '../../assets/img/portfolio/tangle-coder.png'
// import restocafe from '../../assets/img/portfolio/restocafe.png'
import excellenciaServicesCapture from '../../assets/img/portfolio/excellencia-services-capture.png'
// import liomCartCapture from '../../assets/img/portfolio/liom-cart-capture.png'
import sahay from '../../assets/img/portfolio/Sahay-Screencapture.png'
// import liomInternationalCapture from '../../assets/img/portfolio/liom-international-capture.png'

export const portfolios = [
    {
        "src": gacmaninagar,
        "title": "GAC Maninagar",
        "url": "http://gacmaninagar.com/"
    },
    {
        "src": gacc,
        "title": "Gacc",
        "url": "http://www.gacc.org.in/"
    },
    {
        "src": perfect,
        "title": "Perfect Industry",
        "url": "http://perfectind.com/"
    },
    {
        "src": dogo,
        "title": "Dogo Health Care",
        "url": "https://dogohealthcare.com/"
    },
    {
        "src": kangaroo,
        "title": "Kangaroo intermediate",
        "url": "http://kangaroointermediate.com/"
    },
    {
        "src": siddhimart,
        "title": "Siddhi Mart",
        "url": "https://siddhimart.com/"
    },
    {
        "src": madhav,
        "title": "madhav Idustries",
        "url": "http://themadhavengineering.com/"
    },
    {
        "src": legalAccure,
        "title": "Legal Accure",
        "url": "http://legalaccure.in/"
    },
    {
        "src": mastercoteind,
        "title": "Master-Cote India",
        "url": "http://mastercoteind.com/"
    },
    {
        "src": advanceimagingsolution,
        "title": "Advance Imaging Solution",
        "url": "http://www.advanceimaging.co.in/"
    },
    {
        "src": tfipl,
        "title": "TFIPL",
        "url": "http://www.tfipl.com/"
    },
    {
        "src": excellenciaServicesCapture,
        "title": "Excellencia Services Captures",
        "url": ""
    },
    {
        "src": sahay,
        "title": "Sahay",
        "url": ""
    }
];
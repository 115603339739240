import current from "../../assets/img/litImages/career1.jpg"
import current2 from "../../assets/img/litImages/career2.jpg"

export default function Current(){
    return(
        <div className="container lit-container">
        
            <div className="row">
                <div className="col-md-12 text-center">
                    <h4 className="p-0 m-0">Current Vacancies </h4>  
                    <hr className="w-50 mx-auto" />
                </div>  
                
                <div className="col-md-8">
                    <img src={current} width="100%" alt="Career in LIOM IT HUB Web Development" />
                </div>
                <div className="col-md-4 my-auto">
                    <h5 className="lit-textThemeAlt">This is the start of something great</h5> 
                    <p>We don’t believe in hiring smart people and telling them what to do. Working at Bench means you can shape your career in a way that’s meaningful for you—and we’re with you every step of the way.</p>                   

                    
                </div>
                <div className="col-md-4 my-auto">
                    <h5 className="lit-textThemeAlt">We’ve got you covered</h5>
                    <p>We don’t believe in hiring smart people and telling them what to do. Working at Bench means you can shape your career in a way that’s meaningful for you—and we’re with you every step of the way.</p>
                </div>
                <div className="col-md-8">
                    <img src={current2} width="100%" alt="Career in LIOM IT HUB Web Development" />
                </div>
            </div>
        </div>
    )
}
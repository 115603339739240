export default function Served(){
    return(
        <div className="container lit-container">
            <h4>Working with clients in...</h4>
            <div className="row pt-3">
                <div className="col-sm-4" style={{marginTop: '15px'}}>
                    <img src={require('assets/img/litImages/canada.png').default} alt="Country Canada" width="100%" className="lit-my-auto" />
                </div>
                <div className="col-sm-4" style={{marginTop: '15px'}}>
                    <img src={require('assets/img/litImages/india.svg').default} alt="Country India" width="100%" className="lit-my-auto" />
                </div>
                <div className="col-sm-4" style={{marginTop: '15px'}}>
                    <img src={require('assets/img/litImages/australia.png').default} alt="Country Australia" width="100%" className="lit-my-auto" />
                </div>
                <div className="col-12 text-center py-3 font-weight-bold">
                    Although we are located in USA, Russia, Germany, Japan, England, Georgia, our work spans the globe.<br />Conference calls with clients in faraway places are part of normal day at the office.
                </div>
            </div>
        </div>
    )
}
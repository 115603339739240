import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAllBlogs } from "network/Api";
import { backApi } from "network/const";
import { lastPage } from "network/Api";
import { BackToTop } from "components/BackToTop";

export default function BlogList(){

    const [page, setPage] = useState(1)
    const [blogs, setBlogs] = useState([])
    const [maxPage, setMaxPage] = useState(1)

    useEffect(()=>{
        BackToTop();
        (async () => {
            let res = await getAllBlogs({page})
            setBlogs(res.data)
        })()
    }
    , [page])

    useEffect(()=>{
        (async () => {
            let res = await lastPage()
            setMaxPage(res)
        })()
    }, [])

    return(
        <>
            <div className="container py-5">
                <div className="row justify-content-center">
                    {
                        blogs.map((dataTemp,index)=>(
                            <div className="col-sm-4 my-2" key={index}>
                                <div className="shadow-sm lit-featBlogCard">
                                    <img src={backApi + dataTemp.photo.formats.small.url} alt={dataTemp.title} />
                                    <div className="px-3 py-1">
                                        <p>{dataTemp.createdAt}</p>
                                        <h5>{dataTemp.title}</h5>
                                        <span>{(dataTemp.details).substring(0,200) + '...'}</span>
                                        <br />
                                        <Link to={"/blog/id/" + dataTemp._id }>Read More &gt;&gt;</Link>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="pt-3 row">
                    <div className="col text-left">
                        <button className="btn btn-primary" onClick={()=>{setPage(page-1)}} disabled={((page===1)?true:false)}>{"<<"} Previous</button>
                    </div>
                    <div className="col text-right">
                        <button className="btn btn-primary" onClick={()=>{setPage(page+1)}} disabled={((page===maxPage)?true:false)}>Next {">>"}</button>
                    </div>                
                </div>
            </div>
        </>
    )
}
export const trainingContent = [
    {
        "title": "Full Stack",
        "img": [ "php", "mysql", "js", "mern" ],
        "content": "In this webpack, we provide you PHP, JavaScript, MERN Stack (MongoDB, Express, React, Node) and MySQL Database. With help of this Technologies you can develop amazing web application, Stand alone REST APIs, Static Apps, Dynamic Web Apps, etc..."
    },{
        "title": "Front-End",
        "img": [ "html", "css", "js", "react" ],
        "content": "In this webpack, we provide you HTML5, JavaScript, CSS3 and React (Front-End Framework of Javascript). With help of this Technologies you can develop amazing web application, Static Apps, Dynamic Web Apps, etc..."
    },{
        "title": "Back-End",
        "img": [ "php", "mysql", "node", "mongo" ],
        "content": "In this webpack, we provide you PHP, MySQL Database, NODE.JS and MongoDB Database. With help of this Technologies you can develop amazing web application, Stand alone REST APIs, Static Apps, Dynamic Web Apps, etc..."
    }
]
import { BackToTop } from "components/BackToTop";
import { getBlog } from "network/Api";
import { backApi } from "network/const";
import { useEffect, useState } from "react";
import { useParams, Redirect } from 'react-router-dom';


export default function SingleBlog(){
    const {id} = useParams()
    const [blogs, setBlogs] = useState({
        "data":{
            "title":"",
            "photo":{
                "formats":{
                    "thumbnail":{"url":""},
                    "small":{"url":""},
                    "medium":{"url":""},
                    "large":{"url":""}
                }
            },
            "details":""
        }
    })

    useEffect(()=>{
        BackToTop();

        (async ()=>{
            getBlog({id}).then(res=>{
                console.log(res)
                setBlogs(res)
            }).catch(()=>{
                console.log('error');
                <Redirect to="/404" />
            })
        })()
    }
    , [id])

    return(
        <>
            <>
                <div
                    className="page-header section-dark lit-small"
                    style={{
                        backgroundImage: "url('"+ backApi + blogs.data.photo.formats.medium.url +"')",
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: 'purple',
                    }}
                >
                    <div className="filter" />
                    <div className="content-center">
                        <h1>{blogs.data.title}</h1>
                    </div>
                </div><br /><br />
            </>
            <div className="row container mx-auto lit-container pb-5">
                {blogs.data.details}
            </div>
        </>
    )
}
import React from 'react'
import Contact from './contact/Contact'
import Looking from './career/Looking'
import Header from './career/Header'
import Current from './career/Current'
import Key from './career/Key'
import { useEffect } from "react";
import { BackToTop } from 'components/BackToTop'

export default function Career() { 
  useEffect(() => {
      BackToTop();
  }, [])
    return (
        <>
            <Header />
            <Looking />
            <Current />
            <Key />
            <Contact />
        </>
    )
}
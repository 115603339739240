import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

import "bootstrap/scss/bootstrap.scss";
import "assets/scss/paper-kit.scss?v=1.3.0";
import "assets/demo/demo.css?v=1.3.0";
import "assets/css/lit.css";

import Topbar from "components/Headers/Topbar"
import CommonNavbar from "components/Headers/CommonNavbar"
import Footer from "components/Footers/Footer"

import Home from "views/Home.js";
import ContactPage from "views/Contact"
import Portfolio from "views/Portfolio"
import About from "views/About";
import Career from "views/Career";
import Error404 from "views/404";
import Training from "views/Training";

import ServicesDemo from "views/services/ServicesDemo"
import Blog from "views/Blog";
import SingleBlog from "views/SingleBlog";
import Technologies from "views/Technologies";

ReactDOM.render(
  <BrowserRouter>
    <Topbar />
    <CommonNavbar />
    <Switch>
      
      <Route path="/" exact render={(props) => <Home {...props} />} />

      <Route path="/contact" render={(props) => <ContactPage {...props} />} />
      <Route path="/portfolio" render={(props) => <Portfolio {...props} />} />
      <Route path="/about" render={(props) => <About {...props} />} />
      <Route path="/career" render={(props) => <Career {...props} />} />
      <Route path="/training" render={(props) => <Training {...props} />} />
      <Route path="/technologies" exact render={(props) => <Technologies {...props} />} />
      <Route path="/404" render={(props) => <Error404 {...props} />} />
      <Route path="/blog" exact render={(props) => <Blog {...props} />} />

      <Route path="/service/:id" render={(props) => <ServicesDemo {...props} />} />
      <Route path="/blog/id/:id" render={(props) => <SingleBlog {...props} />} />

      <Redirect to="/404" />

    </Switch>
    <Footer />
  </BrowserRouter>,
  document.getElementById("root")
);

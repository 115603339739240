import React from "react";

export default function Header() {
  return (
    <>
      <div
        className="page-header section-dark lit-trainingBG lit-small"
        style={{
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundColor: 'purple',
        }}
      >
        <div className="filter" />
        <div className="content-center">
            {/* <Slider /> */}
            <h1>Training in LIOM</h1>
        </div>
        {/* <div
          className="moving-clouds"
          style={{
            backgroundImage:
              "url(" + require("assets/img/clouds.png").default + ")",
          }}
        /> */}
      </div><br /><br />
    </>
  );
}

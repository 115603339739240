import React from "react";
import key1 from "../../assets/img/litImages/liomfam1.png"
import key2 from "../../assets/img/litImages/liomfam2.png"
import key3 from "../../assets/img/litImages/liomfam3.png"
import key4 from "../../assets/img/litImages/liomfam4.png"
import key5 from "../../assets/img/litImages/liomfam5.png"
import key6 from "../../assets/img/litImages/liomfam6.png"

export default function Key() {
    return (
          <div className="container  lit-container ">
        <div className="row py-5 lit-keyAch ">

           <div className="col-md-12 text-center">
                    <h4 className="p-0 m-0">With LIOM Family You Get</h4>  
                    <hr className="w-50 mx-auto" />
                </div>  
           <div className="col-sm-4 text-center my-2">
                <div className="shadow-sm border p-3 lit-keyAchCard rounded"> 
                    <img src={key1} alt="successful project" /> 
                    <hr className="w-50 mx-auto" />
                    <h5>Innovative Work Culture</h5>
                </div>
            </div>
            <div className="col-sm-4 text-center my-2">
                <div className="shadow-sm border p-3 lit-keyAchCard rounded">   
                    <img src={key2} alt="Countries Served" /> 
                    <hr className="w-50 mx-auto" />
                    <h5>Paycheck You Love</h5>
                </div>
            </div>
            <div className="col-sm-4 text-center my-2">
                <div className="shadow-sm border p-3 lit-keyAchCard rounded"> 
                     <img src={key3} alt="Skill Engineers" /> 
                    <hr className="w-50 mx-auto" />
                    <h5>Flexible Timings</h5>
                </div>
            </div>
            <div className="col-sm-4 text-center my-2">
                <div className="shadow-sm border p-3 lit-keyAchCard rounded"> 
                    <img src={key4} alt="successful project" /> 
                    <hr className="w-50 mx-auto" />
                    <h5>Event Celebrations</h5>
                </div>
            </div>
            <div className="col-sm-4 text-center my-2">
                <div className="shadow-sm border p-3 lit-keyAchCard rounded">   
                    <img src={key5} alt="Countries Served" /> 
                    <hr className="w-50 mx-auto" />
                    <h5>Ample Growth Opportunities</h5>
                </div>
            </div>
            <div className="col-sm-4 text-center my-2">
                <div className="shadow-sm border p-3 lit-keyAchCard rounded"> 
                     <img src={key6} alt="Skill Engineers" /> 
                    <hr className="w-50 mx-auto" />
                    <h5>Employee Friendly Leave Policies</h5>
                </div>
            </div>
        </div>   
        </div>   
    )
}
import React from "react";

import process1 from '../../assets/img/litImages/process1.png'
import process2 from '../../assets/img/litImages/process2.png'
import process3 from '../../assets/img/litImages/process3.png'
import process4 from '../../assets/img/litImages/process4.png'


export default function Process() {
  return (
      <div className="lit-bg-ws ">
        <div className="container  lit-container">
            <div className="row py-5 lit-process">
                <h4 className="col-12 lit-headLight my-3">Our Process</h4>
                <div className="col-md-3 my-2 col-sm-6 text-center">
                    <div className="shadow-sm p-3 lit-processCard">   

                        <img src={process1} alt="Analysis" />
                        <h5 className="lit-processTitle">Analysis</h5>
                        <hr className="w-50 mx-auto" />
                        <p className="px-2">
                            Based on your project/product goals, we assess the business & technical needs and document the scope for a smooth execution.
                        </p>
                    </div>
                </div>
                <div className="col-md-3 my-2 col-sm-6 text-center">
                    <div className="shadow-sm p-3 lit-processCard">
                        <img src={process2} alt="Design" />
                        <h5 className="lit-processTitle">Design</h5>
                        <hr className="w-50 mx-auto" />
                        <p className="px-2">
                            We start with creating a sketch/ wireframe of the software and then proceed towards designing a rich user interface envisaging the end user experience.
                        </p>
                    </div>
                </div>
                <div className="col-md-3 my-2 col-sm-6 text-center">
                    <div className="shadow-sm p-3 lit-processCard">  
                        <img src={process3} alt="Development" />
                        <h5 className="lit-processTitle">Development</h5>
                        <hr className="w-50 mx-auto" />
                        <p className="px-2">
                        We take complete responsibility right from designing a scalable architecture, creating a database model, developing the functionalities to conducting unit tests of your software.
                        </p>
                    </div>
                </div>
                <div className="col-md-3 my-2 col-sm-6 text-center">
                    <div className="shadow-sm p-3 lit-processCard">   
                        <img src={process4} alt="QATesting" />
                        <h5 className="lit-processTitle">QA Testing</h5>
                        <hr className="w-50 mx-auto" />
                        <p className="px-2">
                            We follow industry standard QA / QC practices to ensure that the software meets quality standards and is delivered as per your requirement.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}
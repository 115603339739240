import {create} from 'axios'
import { backApi } from './const'

const perPage = 15
const req = create({
    baseURL: backApi,
})

export const getAllBlogs = async ({page}) => {
    return await req.get('/blogs?_sort=createdAt:DESC&_start='+ ((page*perPage)-perPage) +'&_limit='+perPage)
}

export const lastPage = async () => {
    let count = (await req.get('/blogs/count')).data
    return Math.ceil(count/perPage)
}

export const getBlog = async ({id}) => {
    return await req.get('/blogs/'+id)
}

export const get3Blog = async () => {
    let countFeaturedBlog = (await req.get('/blogs/count?featured=true')).data
    return await req.get('/blogs?featured=true&_start='+ (countFeaturedBlog-3) +'&_limit=3')
}
